




import { Component, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class Users extends Mixins(PermissionsMixin) {
  private mounted () {
    if (this.$route.name === 'manager.users') {
      if (this.hasSomePermissions([this.Permission.USERS_MANAGER_LIST, this.Permission.USERS_MANAGER_SEARCH])) {
        this.$router.replace({ name: 'manager.users.employees' })
      } else if (this.hasSomePermissions([this.Permission.USERS_MASTER_LIST, this.Permission.USERS_MASTER_SEARCH])) {
        this.$router.replace({ name: 'manager.users.masters' })
      } else if (this.hasPermission(this.Permission.ROLES)) {
        this.$router.replace({ name: 'manager.users.roles' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }
}
